$(document).ready(function(){

    $('body').on("submit", "#request_edit", function(e){
        e.preventDefault();
    });

    $('body').on('click', "[ajaxRequest='biblioEdit']", function(){
        var route = Routing.generate($(this).attr('route'));
        form = $('#request_edit');
        $.ajax({
            url: route,
            data: {
                id: $(this).attr('request-id'),
                form: form.serializeArray()
            },
            dataType:   'JSON',
            type:       'POST',
            async:      true,  
            success: function(response, status) {
                if (!response.html){
                    $('#biblio-modal-ajax').modal('hide');
                }
                if (response.refresh){
                    refreshBibliotheque();
                }
            },  
            error : function(xhr, textStatus, errorThrown) {  
               console.log('Ajax request failed. EDIT');
            }  
        });
    });


    $('body').on('click',".biblioChevron", function(){
        var identifier = $(this).parent().attr('id').replace('-title', '');
        $('#'+identifier+'-content, #'+identifier+'-title i.fa-chevron-up, #'+identifier+'-title i.fa-chevron-down').toggle("slide");
    });

    $('body').on('mouseover', '[data-toggle="tooltip"]', function (){
       $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'}); 
    });
    
    $('body').on('click', "[ajaxRequest='biblio']", function(){
        var action = $(this).attr('route');
        var route = Routing.generate(action);
        $.ajax({
            url: route,
            data: {
                id: $(this).attr('request-id'),
                name: $(this).attr('request-nom'), 
            },
            dataType:   'JSON',
            type:       'POST',
            async:      true,  
            success: function(response, status) {
                if (response.html.length > 0){
                    $('#container-modal-ajax').html(response.html);
                    $('#biblio-modal-ajax').modal('show');
                }
                if (response.refresh){
                    refreshBibliotheque();
                }
                if (response.message){
                    alert(response.message);
                }
            },
            error : function(xhr, textStatus, errorThrown) {  
                console.log('Ajax request failed.');
            }
        });
    });

    $('body').on('click', "[ajaxRequest='biblioConfirm']", function(){
        var action = $(this).attr('route');
        var route = Routing.generate(action);
        $.ajax({
            url: route,
            data: {
                id: $(this).attr('request-id'),
            },
            dataType:   'JSON',
            type:       'POST',
            async:      true,  
            success: function(response, status) {
                if (!response.html){
                    $('#biblio-modal-ajax').modal('hide');
                }
                if (response.action == 'not_deleted'){
                    alert('action impossible');
                }
                if (response.refresh){
                    refreshBibliotheque();
                }
            },  
            error : function(xhr, textStatus, errorThrown) {  
               console.log('Ajax request failed.');
            }  
        });
    });

    disabledSaveBookcaseInfo("#libelle","#saveBookcase");
    $('body').on('change',"#libelle", function(){
        disabledSaveBookcaseInfo("#libelle","#saveBookcase");
    });



    $('#agregat').change(function(){
        checkAgregat();
    });

    checkAgregat();
});

function checkAgregat(){
    var count = 0;
    $('#agregat select').each(function() {
        if ($(this).val().length > 0)
            ++count;
    });
    if (count > 0){
        $('button#save').prop("disabled",true);
        $("button#save").attr('title', Translator.trans('app.form.agregat.title-btn-save'));
    } else {
        $('button#save').prop("disabled",false);
        $("button#save").attr('title', "");
    } 
}

function refreshBibliotheque(){
    $( "#bibliotheque-part" ).html('');
    $( "#bibliotheque-part" ).load(window.location.href + " #bibliotheque-part > *" );
}
function disabledSaveBookcaseInfo(libelle,bouton){
    if($(libelle).val() == "" ){
        $(bouton).prop('disabled', true);
        $(bouton).attr('title', Translator.trans('app.form.bookcase.title-btn-save-pas-libelle'));
    }
    else{
        $(bouton).prop('disabled', false);
        $(bouton).attr('title', ""); 
    }    
}